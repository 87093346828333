/* ==========================================================================
   TABLES
   ========================================================================== */

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

table {
  display: block;
  margin-bottom: 1em;
  width: fit-content;
  font-family: $global-font-family;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;

  & + table {
    margin-top: 1em;
  }

  caption {
    font-size: $type-size-7;
    color: $caption-text-color;
    border-bottom: 1px solid $caption-border-color;
    width: fit-content;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;

    a {
      -webkit-transition: $global-transition;
      transition: $global-transition;
      text-decoration: none;

      &:hover {
        color: $link-color-hover;
      }
    }
  }
}

thead {
  background-color: $border-color;
  border-bottom: 2px solid mix(#000, $border-color, 25%);
}

thead,
tbody,
tfoot {
  font-size: $type-size-6;
}

th {
  padding: 0.5em;
  font-weight: bold;
  text-align: left;
}

td {
  padding: 0.5em;
  border-bottom: 1px solid mix(#000, $border-color, 25%);
}

tr,
td,
th {
  vertical-align: middle;
}